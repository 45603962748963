import { Document, Page, StyleSheet, Text, Font } from '@react-pdf/renderer'
import moment from 'moment'
import React from 'react'
import { Apprenticeship, TrialApprenticeship } from './Step1'
import {
  ApprenticeshipQuestions,
  TrialApprenticeshipQuestions,
} from './Step2/Questions'
import CalibriRegular from '../../../assets/fonts/Calibri.ttf'
import CalibriBold from '../../../assets/fonts/Calibri-Bold.ttf'

const MALE_GENDER = 'Herr'
const FEMALE_GENDER = 'Frau'
const UNKNOWN_GENDER = 'Unbekannt'

export const GENDERS = {
  [MALE_GENDER]: { title: 'Mr.', description: 'Sehr geehrter Herr' },
  [FEMALE_GENDER]: { title: 'Ms.', description: 'Sehr geehrte Frau' },
  [UNKNOWN_GENDER]: {
    title: 'Unknown',
    description: 'Sehr geehrte Damen und Herren',
  },
}

moment.updateLocale('de', {
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
})

const MotivationLetterPdf = ({ user, value }) => {
  const recipientGender =
    value?.recipient?.gender !== UNKNOWN_GENDER && value?.recipient?.gender

  const greetingBasedOnGender = Object.keys(GENDERS).includes(
    value?.recipient?.gender
  )
    ? value?.recipient?.gender !== UNKNOWN_GENDER
      ? [
          GENDERS[value?.recipient?.gender].description,
          value?.recipient?.lastName,
        ].join(' ')
      : GENDERS[value?.recipient?.gender].description
    : ''

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.heading}>
          {[user.firstName, user.lastName].filter(Boolean).join(' ')}
        </Text>
        <Text style={styles.heading}>{`${user.address}`}</Text>
        <Text style={styles.heading}>
          {[user.postalCode, user.place].filter(Boolean).join(' ')}
        </Text>
        <Text style={styles.heading}>{user.email}</Text>
        <Text style={styles.heading}>{user.phone}</Text>

        <Text style={styles.heading}>{'\n\n'}</Text>

        <Text style={styles.heading}>
          {value?.company?.name ? value?.company?.name : ''}
        </Text>
        <Text style={styles.heading}>
          {[
            recipientGender,
            value?.recipient?.firstName,
            value?.recipient?.lastName,
          ]
            .filter(Boolean)
            .join(' ')}
        </Text>

        <Text style={styles.heading}>
          {value?.company?.address ? value?.company?.address : ''}
        </Text>
        <Text style={styles.heading}>
          {value?.company?.postcode ? value?.company?.postcode + ' ' : ''}
          {value?.company?.city ? value?.company?.city : ''}
        </Text>

        <Text style={styles.heading}>{'\n\n'}</Text>

        <Text style={styles.heading}>
          {[user.place, moment(new Date()).format('DD. MMMM YYYY')]
            .filter(Boolean)
            .join(', ')}
        </Text>

        <Text style={styles.heading}>{'\n\n'}</Text>

        <Text style={styles.heading_bold}>
          {[
            'Bewerbung um eine',
            value?.type === TrialApprenticeship
              ? 'Schnupperlehre'
              : 'Lehrstelle',
            'als',
            value?.job?.title,
            value?.job?.type,
          ]
            .filter(Boolean)
            .join(' ')}
        </Text>

        <Text style={styles.heading}>{'\n\n'}</Text>

        <Text style={{ fontSize: 12 }}>{greetingBasedOnGender}</Text>

        <Text style={styles.heading}>{'\n'}</Text>

        {value?.type === TrialApprenticeship
          ? TrialApprenticeshipQuestions.map((question, index) => {
              return (
                <Text style={styles.heading} key={index}>
                  {value?.questions[question.label]
                    ? value?.questions[question.label]
                    : ''}
                  {'\n\n'}
                </Text>
              )
            })
          : ApprenticeshipQuestions.map((question, index) => {
              return (
                <Text style={styles.heading} key={index}>
                  {value?.questions[question.label]
                    ? value?.questions[question.label]
                    : ''}
                  {'\n\n'}
                </Text>
              )
            })}

        {value?.type === TrialApprenticeship && (
          <Text style={styles.heading}>
            Ideale Termine zum Schnuppern sind für mich:{'\n\n'}
            {Object.keys(value?.appointments).map((key, index) => (
              <Text key={index}>
                {moment(value?.appointments[index + 1]?.from).isValid() &&
                  moment(value?.appointments[index + 1]?.to).isValid() && (
                    <Text className='max-w-[11rem]'>
                      {`${moment(value?.appointments[index + 1].from).format(
                        'DD.MM.YYYY'
                      )} - ${moment(value?.appointments[index + 1].to).format(
                        'DD.MM.YYYY'
                      )}`}
                      {'\n'}
                    </Text>
                  )}
              </Text>
            ))}
            {'\n\n'}
          </Text>
        )}

        {value?.type === Apprenticeship ? (
          <Text style={styles.heading}>
            Ich freue mich, wenn Sie mich zu einem Vorstellungsgespräch
            einladen, damit ich Sie von meiner Persönlichkeit überzeugen kann
            und warte gespannt auf Ihre Antwort.
          </Text>
        ) : (
          <Text style={styles.heading}>Ich freue mich von Ihnen zu hören.</Text>
        )}

        <Text>{'\n\n'}</Text>

        <Text style={styles.heading}>Freundliche Grüsse</Text>

        <Text style={styles.heading}>{'\n\n'}</Text>

        <Text style={styles.heading}>
          {user.firstName} {user.lastName}
        </Text>

        {value.attachments.length ? (
          <Text style={{ position: 'absolute', bottom: 100, fontSize: 12 }}>
            <Text>Beilagen:{'\n'}</Text>
            {value.attachments.join(' / ')}
          </Text>
        ) : null}
      </Page>
    </Document>
  )
}

Font.register({
  family: 'Calibri',
  fonts: [
    {
      src: CalibriRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: CalibriBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  body: {
    marginTop: '1.75cm',
    marginHorizontal: '2.5cm',
    fontFamily: 'Calibri',
  },
  title: {
    fontSize: 20,
    marginBottom: 50,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
  },
  heading: {
    fontSize: 12,
    width: 450,
    textAlign: 'justify',
  },
  heading_bold: {
    fontWeight: 'bold',
    fontSize: 12,
  },

  linebreak: {
    width: '100%',
    // border: '0.5pt solid #bfbfbf',
    marginTop: 30,
    marginBottom: 21,
  },
})

export default MotivationLetterPdf
