import { CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import EditWrapper from './EditWrapper'
import { translation } from 'utils/germanTranslation'

const EditSkills = ({
  pageTitle,
  children,
  description,
  backLink,
  onUpdateClick,
  cancelButtonTitle,
  loading,
  disabled,
  showCancelButton = true,
  submitButtonTitle = translation.update,
  onCancelClick,
  containerWidth = 'full',
}) => {
  const navigate = useNavigate()

  return (
    <div className='flex justify-center w-full h-full bg-softWhite min-h-screen'>
      <EditWrapper
        pageTitle={pageTitle}
        description={description}
        backLink={backLink}
        onUpdateClick={onUpdateClick}
        disabled={disabled}
        showCancelButton={showCancelButton}
        cancelButtonTitle={cancelButtonTitle}
        submitButtonTitle={submitButtonTitle}
        navigate={navigate}
        onCancelClick={onCancelClick}
        containerWidth={containerWidth}
      >
        {loading ? (
          <div className='w-full flex items-center justify-center'>
            <CircularProgress />
          </div>
        ) : (
          children
        )}
      </EditWrapper>
    </div>
  )
}

export default EditSkills
