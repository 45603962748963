import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import EditSkills from 'components/EditSkills'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import NoAccess from 'components/NoAccess'
import VerifyEditAccess from 'components/VerifyEditAccess'
import Step1, { Apprenticeship } from './Step1'
import Step2 from './Step2'
import {
  ApprenticeshipQuestions,
  TrialApprenticeshipQuestions,
} from './Step2/Questions'
import Step3 from './Step3'
import { user } from 'store/reducers/user'
import useGetMotivationLetters from 'hooks/useGetMotivationLetters'
import { updateMotivationLetter } from './updateMotivationLetter'
import _ from 'lodash'
import { uuidv4 } from 'utils'
import { attachmentType } from './Attachments'
import CustomModal from 'components/CustomModal'
import Button from 'components/Button'
import { translation } from 'utils/germanTranslation'

const AddMotivationLetter = () => {
  return (
    <VerifyEditAccess>
      {(id, canEdit, navigate) =>
        canEdit ? (
          <VerifiedAddMotivationLetter userId={id} navigate={navigate} />
        ) : (
          <NoAccess />
        )
      }
    </VerifyEditAccess>
  )
}

const VerifiedAddMotivationLetter = ({ userId, navigate }) => {
  const [otherAttachments, setOtherAttachments] = useState([])

  const initialValue = {
    id: uuidv4(),
    type: Apprenticeship,
    company: {
      name: '',
      address: '',
      postcode: '',
      city: '',
    },
    job: {
      title: '',
      type: '',
    },
    recipient: {
      gender: '',
      firstName: '',
      lastName: '',
    },
    questions: {
      ...TrialApprenticeshipQuestions.reduce(
        (accumulator, question) => ({
          ...accumulator,
          [question.label]: '',
        }),
        {}
      ),
      ...ApprenticeshipQuestions.reduce(
        (accumulator, question) => ({
          ...accumulator,
          [question.label]: '',
        }),
        {}
      ),
    },
    appointments: {
      1: { from: '', to: '' },
      2: { from: '', to: '' },
      3: { from: '', to: '' },
    },
    progress: 0,
    attachments: [],
    modifiedAt: new Date(),
  }

  const loggedInUser = useSelector(user)

  const [params, setParams] = useSearchParams()
  const letterId = params.get('id')
  const { loading: letterLoading, data } = useGetMotivationLetters(
    userId,
    letterId || 'none'
  )
  //
  const [value, setValue] = useState(initialValue)
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleChange = (newValue, field, innerField = null) => {
    if (innerField)
      setValue({
        ...value,
        [field]: {
          ...value[field],
          [innerField]: newValue,
        },
      })
    else
      setValue({
        ...value,
        [field]: newValue,
      })
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      let otherAttachmentsTemp = []
      let mainAttachmentsTemp = []
      if (data.attachments.length) {
        otherAttachmentsTemp = data.attachments.filter(
          (attachment) => !attachmentType[value.type].includes(attachment)
        )
        mainAttachmentsTemp = data.attachments.filter((attachment) =>
          attachmentType[value.type].includes(attachment)
        )
        setOtherAttachments(otherAttachmentsTemp)
      }
      setValue({
        ...data,
        attachments: mainAttachmentsTemp,
      })
    }
  }, [data])

  return (
    <EditSkills
      pageTitle={translation.motivationLetter}
      submitButtonTitle={
        step === 3 ? 'Motivationsschreiben erstellen' : 'weiter'
      }
      onUpdateClick={() =>
        updateMotivationLetter(
          value,
          setValue,
          setLoading,
          userId,
          letterId,
          navigate,
          step,
          setStep,
          otherAttachments,
          loggedInUser
        )
      }
      cancelButtonTitle='zurück'
      onCancelClick={() => {
        if (step !== 1) {
          setStep((prev) => prev - 1)
        } else {
          setShowModal(true)
        }
      }}
    >
      <SimpleEditWrapper
        pageTitle='Diary'
        showAddIcon={false}
        customized={true}
        containerClasses='relative p-4'
      >
        {step === 1 && (
          <Step1 value={value} handleChange={handleChange} step={step} />
        )}
        {step === 2 && (
          <Step2
            value={value}
            handleChange={handleChange}
            step={step}
            otherAttachments={otherAttachments}
            setOtherAttachments={setOtherAttachments}
          />
        )}
        {step === 3 && (
          <Step3
            value={value}
            handleChange={handleChange}
            setValue={setValue}
            step={step}
            user={loggedInUser}
            otherAttachments={otherAttachments}
            setOtherAttachments={setOtherAttachments}
          />
        )}
        <CustomModal open={showModal} handleClose={() => setShowModal(false)}>
          <div>
            <p className='text-lg sm:text-xl font-semibold text-center'>
              Deine Daten gehen verloren und werden nicht gespeichert!
            </p>
            <div className='flex justify-center gap-4 mt-4'>
              <Button
                title='Ja'
                onClick={() => {
                  navigate(-1)
                }}
                containerClasses='px-8 py-2 rounded-lg text-white'
                backgroundColor='#458EFF'
              />
              <Button
                title={translation.cancel}
                onClick={() => setShowModal(false)}
                containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
                backgroundColor='#E9F1FC'
              />
            </div>
          </div>
        </CustomModal>
      </SimpleEditWrapper>
    </EditSkills>
  )
}

export default AddMotivationLetter
